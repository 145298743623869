import React from "react"
import LayoutChild from "../components/layoutChild"
import SEO from "../components/seo"
import { color, fontSize, space } from '../components/constants'
import styled from 'styled-components'
import { graphql } from "gatsby"
import Img from "gatsby-image";


const PageLayout = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 36px;
    margin: 0px 36px 72px;

    @media (max-width: 1024px){
        grid-gap: 24px;
        margin: 0px 24px 24px;
    }

    @media (max-width: 640px){
        grid-template-columns: 100% ;
        ${'' /* grid-template-rows: 100%; */}
        grid-gap: 24px;
        margin: 0px 24px 24px;
    }
`
/* Text Group */
const CellTitleGroup = styled.div`
    width: 75%;
    @media (max-width: 640px){
        ${'' /* grid-row: 2; */}
        width: 75%;
        margin-top: 8px;
    }

`
const Title = styled.div`
    color: white;
    font-size: ${fontSize[3]};
    font-weight: 200;
    margin-top: ${space[4]+ "px"};
    line-height: 0.8;
    word-wrap:normal; /* YESSSSS */
    text-rendering: optimizeLegibility;

    @media (max-width: 1024px){
        font-size: ${fontSize[2]};
    }

    @media (max-width: 640px){
        word-spacing: normal;
        font-size: ${fontSize[2]};
    }

`
const Text = styled.p`
    margin-top: ${space[2]+ "px"};
    word-wrap:normal; /* YESSSSS */
    color: ${color.gray2};
    @media (max-width: 1024px){
        font-size: 16px;
    }
`
const Subhead = styled.p`
    margin-top: ${space[4]+ "px"};
    word-wrap:normal; /* YESSSSS */
    color: white;
    margin-bottom: 0px;
    @media (max-width: 1024px){
        font-size: 16px;
    }
`
/* Image styles */

const ImgSingle = styled(Img)`
    border-radius: 24px;
`
const ImgDouble = styled(Img)`
    border-radius: 24px;
    grid-column: 1 / span 2;
    @media (max-width: 640px){
        grid-column: 1 / span 1;
    }
`

export default ({ data }) => (
  <LayoutChild>
    <SEO 
      title="Scroll Reader App"
      description="A companion app to the Scroll experience"
      image="../images/card.jpg"
    />
    <PageLayout>
        <CellTitleGroup>
            <Title >Scroll Reader&nbsp;App</Title>
            <Subhead>ROLE: UX LEAD DESIGNER</Subhead>
            <Text>The Scroll Reader app is a companion app to the Scroll experience that tracks your history and surfaces interesting articles. The app was designed to reflect the clean, clutter-free experience Scroll promises on the web.
            Within the app, articles are presented with a clean, ad- free reader and reading&nbsp;tools.</Text>
            <Subhead>Research, concept, interaction design, design system, visual design, and&nbsp;prototyping.</Subhead>
            </CellTitleGroup>
            <ImgSingle 
                fluid={data.imageOne.childImageSharp.fluid}
                alt="An image of the Scroll Reader App"
                fadeIn={true}
                 />
            <ImgDouble 
                fluid={data.imageTwo.childImageSharp.fluid}
                alt="An image of the Scroll Reader App"
                fadeIn={true}
                 />
            <ImgSingle 
                fluid={data.imageThree.childImageSharp.fluid}
                alt="An image of the Scroll Reader App"
                fadeIn={true}
                 />
            <ImgSingle 
                fluid={data.imageFour.childImageSharp.fluid}
                alt="An image of the Scroll Reader App"
                fadeIn={true}
                 />
         <ImgDouble 
                fluid={data.imageFive.childImageSharp.fluid}
                alt="An image of the Scroll Reader App"
                fadeIn={true}
                 />
         <ImgDouble 
                fluid={data.imageSix.childImageSharp.fluid}
                alt="An image of the Scroll Reader App"
                fadeIn={true}
                 />
        </PageLayout>
  </LayoutChild>
)

export const query = graphql`
  query {
    imageOne: file(relativePath: { eq: "scroll/scroll1.webp" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 1440) {
            ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    imageTwo: file(relativePath: { eq: "scroll/scroll2.webp" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    imageThree: file(relativePath: { eq: "scroll/scroll3.webp" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    imageFour: file(relativePath: { eq: "scroll/scroll4.webp" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    imageFive: file(relativePath: { eq: "scroll/scroll5.webp" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      imageSix: file(relativePath: { eq: "scroll/scroll6.webp" }) {
        childImageSharp {
          # Specify the image processing specifications right in the query.
          # Makes it trivial to update as your page's design changes.
          fluid(maxWidth: 1440) {
              ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
  }
`